import axios from "@/core/services/axios";
import router from "@/router";
import isQualified from "@/core/helpers/checkQualification";
import qs from "qs";
import _ from "lodash";

const SET_CURRENT_STEP = "SET_CURRENT_STEP";
const SET_SUBMISSION_STATUS = "SET_SUBMISSION_STATUS";
const SET_SUBMISSION_INFO = "SET_SUBMISSION_INFO";
const SET_INTERMEDIARY_LIST = "SET_INTERMEDIARY_LIST";
const SET_ADVISORS_INFO = "SET_ADVISORS_INFO";
const SET_MATCH_LIST = "SET_MATCH_LIST";

const SET_SUBMISSION_LIST = "SET_SUBMISSION_LIST";

const state = {
  step: 1,
  status: {
    step: 1,
    view: "application",
  },
  submission: {},
  intermediaryList: {
    type: "",
    list: [],
  },
  advisorsInformation: [],
  matchList: [],
  submissions: [],
};

const mutations = {
  [SET_CURRENT_STEP](state, payload) {
    state.step = payload;
  },
  [SET_SUBMISSION_STATUS](state, payload) {
    state.status = payload;
  },
  [SET_SUBMISSION_INFO](state, payload) {
    state.submission = payload;
  },
  [SET_INTERMEDIARY_LIST](state, payload) {
    state.intermediaryList = payload;
  },
  [SET_ADVISORS_INFO](state, payload) {
    state.advisorsInformation = payload;
  },
  [SET_MATCH_LIST](state, payload) {
    state.matchList = payload;
  },

  [SET_SUBMISSION_LIST](state, payload) {
    state.submissions = payload;
  },
};
const statusMap = {
  new: { step: 1, view: "application" },
  application: { step: 1, view: "onboarding" },
  rejected: { step: 2, view: "unqualified" },
  applied: { step: 2, view: "applied" },
  inPipeline: { step: 2, view: "inPipeline" },
  inPipelineReview: { step: 2, view: "inPipeline" },
  inWorkplan: { step: 3, view: "inWorkplan" },
  archived: { step: 4, view: "archived" },
  published: { step: 4, view: "published" },
  funded: { step: 4, view: "funded" },
};
const actions = {
  createDraftSubmission({ commit, dispatch }) {
    const data = {
      submission: {},
    };

    const state = "new";
    return axios
      .post("/alien/submissions?entity=investee", {
        platform: "awf",
        state,
        data,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO, submission);

        const status = statusMap[response.data.state];
        commit(SET_SUBMISSION_STATUS, status);

        dispatch("setCurrentStep", status.step);
      });
  },
  createNewSubmission({ commit, state, dispatch }, payload) {
    const currentSubmission = state.submission;
    delete currentSubmission._id;

    const submissionState = isQualified(payload.interest)
      ? "application"
      : "rejected";

    const data = {
      ...currentSubmission,
      data: {
        submission: {
          ...currentSubmission.data.submission,
          ...payload,
        },
      },
      state: submissionState,
    };

    return axios
      .post("/alien/submissions?entity=investee", data)
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO, submission);

        const status = statusMap[response.data.state];
        commit(SET_SUBMISSION_STATUS, status);

        dispatch(
          "messages/showSuccessMessage",
          {
            text: "Thank you for submitting your application!",
            confirmButtonText: "Ok",
          },
          { root: true }
        ).then(() => {
          dispatch("setCurrentStep", status.step);
        });
      });
  },
  sendApplicationStep({ commit, dispatch }, payload) {
    var status = {};
    // TODO: on send step use submission state instead of status
    if (
      isQualified({
        country: payload.company.baseCountry,
        yearOfIncorporation: payload.company.yearOfIncorporation,
        twoYearsAuditedFinancial: !!payload.interest.twoYearsAuditedFinancial,
        amountOfFinancingSoughtInUSD:
          payload.interest.amountOfFinancingSoughtInUSD,
        finRevenue: payload.interest.finRevenue,
      })
    ) {
      status = { step: 2, view: "onboarding" };
    } else {
      status = {
        step: 2,
        view: "unqualified",
      };
    }

    var data = {
      submission: payload,
      status,
    };
    const currentSubmission = state.submission;
    delete currentSubmission._id;

    return axios
      .post("/alien/submissions?entity=investee", {
        ...currentSubmission,
        platform: "fsd",
        data,
      })
      .then(() => {
        dispatch(
          "messages/showSuccessMessage",
          {
            text: "Thank you for submitting your application!",
            confirmButtonText: "Ok",
          },
          { root: true }
        ).then(() => {
          commit(SET_SUBMISSION_STATUS, status);
          dispatch("setCurrentUserSubmission");
        });
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setCurrentStep({ commit, state }, payload) {
    commit(SET_CURRENT_STEP, payload);
    const steps = {
      1: "application",
      2: "review",
      3: "bdr",
      4: "investment",
    };

    const submissionId = _.get(
      router,
      "currentRoute.value.params.submissionId",
      state.submission.submissionId
    );

    router.push({
      name: steps[payload],
      params: { submissionId },
    });
  },
  setCurrentSubmission({ commit, dispatch, state }, submissionId) {
    if (submissionId === "new") {
      dispatch("setCurrentStep", 1);
      return new Promise((resolve) => resolve({}));
    }
    if (state.submissions.length) {
      const currentSubmission = state.submissions.find(
        (submission) => submission.submissionId === submissionId
      );
      if (currentSubmission) {
        const status = statusMap[currentSubmission.state];

        commit(SET_SUBMISSION_STATUS, status);
        commit(SET_SUBMISSION_INFO, currentSubmission);
        dispatch("setCurrentStep", status.step);

        return new Promise((resolve) => resolve(currentSubmission));
      }
    }
    return axios
      .get(`/alien/submissions/${submissionId}`)
      .then((response) => {
        const userSubmission = response.data.items[0];
        if (userSubmission) {
          const status = statusMap[userSubmission.state];
          commit(SET_SUBMISSION_STATUS, status);

          commit(SET_SUBMISSION_INFO, userSubmission);
          dispatch("setCurrentStep", status.step);
        }
        return userSubmission;
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setNewStatus({ dispatch, state, commit }, payload) {
    var data = {
      submission: state.submission,
      status: payload,
    };
    return axios
      .post("/alien/submissions?entity=investee", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS, status);
        dispatch("setCurrentStep", status.step);
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  updateSubmission({ dispatch, commit, state }, { submission, status }) {
    const currentSubmission = state.submission;
    delete currentSubmission._id;
    if (!status) {
      status = currentSubmission.state;
    }
    return axios
      .post("/alien/submissions?entity=investee", {
        ...currentSubmission,
        data: {
          submission: { ...currentSubmission.data.submission, submission },
        },
        state: status,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO, submission);

        const status = statusMap[response.data.state];
        commit(SET_SUBMISSION_STATUS, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  setIntermediaryList({ commit, dispatch }, payload) {
    return axios
      .get("/alien/available-intermediaries", { params: { type: payload } })
      .then((response) => {
        commit(SET_INTERMEDIARY_LIST, {
          list: response.data.items,
          type: payload,
        });
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  updateAdvisorObject({ dispatch }, payload) {
    return axios
      .patch("/alien/advisors/" + payload._id, payload)
      .then(() => {
        dispatch("setAdvisorInformation");
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setAdvisorInformation({ commit, state }) {
    const submissionId = state?.submission?.submissionId;
    return axios
      .get(`/alien/advisors?investeeSubmissionId=${submissionId}`)
      .then((response) => {
        commit(SET_ADVISORS_INFO, response.data.items);
      });
  },
  setMatchList({ state, commit }, payload) {
    const submissionId = state?.submission?.submissionId;
    return axios
      .get("/alien/matches", {
        params: {
          investeeSubmissionId: submissionId,
          status: payload,
          platform: "awf",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
      .then((response) => {
        commit(SET_MATCH_LIST, response.data.items);
      });
  },
  updateMatchObject({ dispatch }, payload) {
    return axios.patch("/alien/match/" + payload._id, payload).then(() => {
      dispatch("setMatchList", "inProgress");
    });
  },

  getSubmissions({ dispatch, commit, rootGetters }) {
    const userId = rootGetters["user/userId"];
    return axios
      .get("/alien/submissions?entity=investee&platform=awf")
      .then((response) => {
        const userSubmissions = response.data.items.filter(
          (item) => item.userId === userId
        );

        if (userSubmissions.length) {
          commit(SET_SUBMISSION_LIST, userSubmissions);
        }
        return userSubmissions;
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  cleanCurrentSubmission({ commit }) {
    commit(SET_SUBMISSION_INFO, {});
  },
};

const getters = {
  currentStep: (state) => state.step,
  submission: (state) => state.submission?.data?.submission || false,
  submissionState: (state) => state.submission?.state || "",
  isStepFinished: (state) => (num) => state.status.step > num,
  view: (state) => state.status.view,
  status: (state) => state.status,
  intermediaryListInfo: (state) => state.intermediaryList,
  advisorsInformation: (state) => state.advisorsInformation,
  matchList: (state) => state.matchList,
  submissionsList: (state) => state.submissions,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
